<template>
  <div class="about">
    <h1>This is an about page</h1>
  </div>
</template>

<script>
// @ is an alias to /src
//import allCars from "../data/allCars";
// eslint-disable-next-line no-unused-vars
//import VehicleService from "../service/vehicle";
//import { axiosTNL } from "../service/axiosInstance";

export default {
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
  filters: {},
  watch: {}
};
</script>
